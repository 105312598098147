<template>
  <main class="result main-wrapper__result-section">
    <div class="result__wrapper">
      <div class="result__inner">
        <p class="result__subtitle">У вас</p>
        <div class="result__details">
          <p>{{ this.result }}</p>
          <p>/</p>
          <p>{{ questionsLength }}</p>
        </div>
        <p class="result__subtitle">балів</p>
      </div>
      <p v-if="quizResult <= 4" class="result__info">
        Йой, хтось тут не надто уважний! Може, ще раз неспішно пройдетеся
        виставкою, якщо маєте час?
      </p>
      <p v-else-if="quizResult <= 8" class="result__info">
        Треба трішки більше задіяти пам’ять. Може, спробуєте зіграти ще разок?
      </p>
      <p v-else-if="quizResult <= 12" class="result__info">
        Чудовий результат! Бігом до медіатора, забирати свій квиток, а потім
        додому — пророщувати плоди :)
      </p>
    </div>
    <router-link to="/" class="btn rules__btn">Спробувати ще раз</router-link>
  </main>
</template>

<script>
export default {
  data() {
    return {
      result: 0,
    };
  },
  name: "result",
  computed: {
    quizResult() {
      return this.$store.getters.userPoints;
    },
    questionsLength() {
      return this.$store.getters.questionsLength;
    },
  },
  mounted() {
    this.result = this.$store.getters.userPoints;
    console.log("userPoints", this.$store.getters.userPoints);
  },
};
</script>

<style>
</style>
